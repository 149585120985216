<template lang="pug">
  .manager-accounts-show
    b-row.mb-5
      b-col
        accounts-panel(ref='accountsPanel')
    b-row.mb-5
      b-col
        router-view(@account-changed='reloadAccountsPanel')
</template>

<script>
import AccountsPanel from '@views/container/manager/accounts/Panel'

export default {
  name: 'manager-accounts-show',
  components: {
    AccountsPanel,
  },
  methods: {
    reloadAccountsPanel() {
      this.$refs.accountsPanel.panelReload()
    },
  },
}
</script>
